#not-found {
    --font-size-title: var(--font-size-xxsmall);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        // --font-size-title: 21vw;
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-s);
    }
}

#not-found {
    @include font-sans();
    padding: calc(var(--header-height) / 2 + var(--padding-v)) var(--padding-h) var(--padding-v);
    position: absolute;
    text-align: center;
    color: var(--primary-color);

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100%;
    width: 100vw;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    
    a {
        @include font-text();
        font-size: var(--font-size-title);
        @include basic-a();
        color: var(--primary-color);
    }

    h1 {
        @include font-sans();
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-s);
    }
}
