.block-project-list {    
    --height-slide: 40vh;
    --width-slide: auto;
    --gap: var(--padding-s);
    --slider-radius: 0;
    --height: 100vh;
    --direction: 1;
    --font-size: var(--font-size-xsmall);
    
    @media (max-width: $smartphone) {
        --gap: var(--padding-xs);
        --height: calc(var(--vh, 1vh) * 100);
        --height-slide: #{rem(240)};
        --gap: var(--padding-s);
    }
}

.block-project-list {
    user-select: none;
    width: 100vw;
    overflow: hidden;
    height: var(--height);
    background-color: rgba(0, 0, 0, .05);

    > div {
        height: 100%;
    }

    .holder {
        gap: var(--gap);

        justify-content: flex-start;
        align-items: center;
        display: flex;
        flex-wrap: nowrap;

        height: 100%;
        left: 0;
        position: relative;
        top: 0;
    }

    &__item {
        will-change: transform;
        overflow: hidden;
        padding: 0 var(--padding-xs);
        height: var(--height-slide);
                
        > div {
            --x: calc(100% * var(--direction));

            &.--top { transform-origin: var(--x) 120%; }
            &.--middle { transform-origin: var(--x) 50%; }
            &.--bottom { transform-origin: var(--x) -20%; }

            transition: transform-origin 1.6s var(--ease-in-circ);
            height: 100%;
            
            * {
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }

            a {
                position: relative;
                display: block;
                width: auto;
                height: 100%;

                @include basic-a();
                @include font-text();
                font-size: var(--font-size);
                color: var(--primary-color);

                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    position: absolute;
                    @include z-index(1);
                    // mix-blend-mode: difference;
                }

                img {
                    @include z-index(0);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            svg {
                height: 100%;
                width: auto;
            }
        }
    }

    @media (max-width: $smartphone) {
        &__item {
            width: 100%;
        }
    }

    @media (min-width: $smartphone) {
        .holder {
            margin-left: 15vw;
        }

        // &__item {
        //     height: 100%;
        //     height: var(--height-slide);
        // }
    }
}