@use "sass:math";

:root {
    --y-header: 0;

    --padding-xxl: #{math.div(60px, 16px) * 1rem};
    --padding-xl: #{math.div(50px, 16px) * 1rem};
    --padding-l: #{math.div(40px, 16px) * 1rem};
    --padding-m: #{math.div(35px, 16px) * 1rem};
    --padding-s: #{math.div(25px, 16px) * 1rem};
    --padding-xs: #{math.div(20px, 16px) * 1rem};
    --padding-xxs: #{math.div(15px, 16px) * 1rem};
    --padding-xxxs: #{math.div(5px, 16px) * 1rem};

    --header-height: #{math.div(70px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --header-height: #{math.div(58px, 16px) * 1rem};
    }
}
