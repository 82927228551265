#Interface__Canvas,
#scene-three,
#scene-target {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    pointer-events: none;
}

#Interface__Canvas {
    @include z-index($z-index-interface);
}

#scene-three,
#scene-target {
    @include z-index($z-index-sidemenu);
}

#scene-target {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
    --screenSize: 100vw;
    --targetWidth: 100vw;
    --left: calc((var(--screenSize) - var(--targetWidth)) / 2);
    // --top: 50vh;

    @media (min-width: $smartphone) {
        --targetWidth: 50vh;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-v) var(--padding-h);

    .target {
        width: var(--targetWidth);
        height: var(--targetWidth);
        top: var(--top);
        position: absolute;
        left: var(--left);
    }
}

#HeaderTarget {
    display: block;
    width: rem(42);
    height: rem(54);
    position: fixed;
    left: 50%;
    top: var(--padding-s);
    transform: translate3d(-50%, 0, 0);

    @media (max-width: $smartphone) {
        top: var(--padding-xs);
        // top: calc(-1 * var(--padding-s));
        width: rem(28);
        height: rem(36);
    }
}
