.widget-about {
    --font-size: var(--font-size-xxsmall);

    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxl);
        --padding-h: var(--padding-xxs);
    }
}

.widget-about {
    color: var(--primary-color);

    @include font-text();
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    
    a {
        @include basic-a();
        color: inherit;
    }

    .text li a {
        @include isCursor() {
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    .title {
        @include font-sans();
        margin-bottom: var(--padding-s);
    }

    .text + .title {
        margin-top: var(--padding-l);
    }

    .text + .text {
        margin-top: var(--padding-xs);
    }

    .wrapper {
        margin-top: var(--padding-xl);
    }

    .rrss {
        @include font-sans();
    }

    @media (max-width: $smartphone) {
        .wrapper {
            > div:first-child {
                margin-bottom: var(--padding-l);
            }
        }
        
        .rrss {
            margin-top: var(--padding-l);
            display: flex;
            gap: var(--padding-xxs);
            
            a:first-child {
                align-items: center;
                display: flex;
                justify-content: flex-start;                

                &:after {
                    content: '·';
                    margin-left: var(--padding-xxs);
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        .wrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            > div {
                width: rem(360);
            }
        }

        .rrss {
            display: flex;
            flex-direction: column;

            margin-top: rem(180);
        }
    }
}
