@use "sass:math";

#Header,
#Footer {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-l);
    --font-size: var(--font-size-xxsmall);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxs);
        --padding-h: var(--padding-xxs);
    }
}

#Header,
#Footer {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--color);
    // mix-blend-mode: difference;

    @include font-sans();
    font-size: var(--font-size);

    a {
        @include basic-a();
        color: inherit;
        display: block;

        padding: var(--padding-xxxs);
        border-radius: rem(5);
        backdrop-filter: blur(5px);
    }
}

#Footer {
    bottom: 0;

    > div {
        @include basic-a();
        color: inherit;

        padding: var(--padding-xxxs);
        border-radius: rem(5);
        backdrop-filter: blur(5px);
    }

    @media (max-width: $smartphone) {
        height: auto;
        align-items: flex-end;
    }

    @media (min-width: $smartphone) {
        br {
            display: none;
        }
    }
}

#Header {
    top: 0;
}
