@use "sass:math";

:root {
    --font-sans: 'NTSEAWAVE', sans-serif;
    --font-text: 'HelveticaNeueLight', sans-serif;

    --font-size-xxlarge: #{math.div(350px, 16px) * 1rem};
    --font-size-large: #{math.div(75px, 16px) * 1rem};
    --font-size-xsmall: #{math.div(18px, 16px) * 1rem};
    --font-size-xxsmall: #{math.div(14px, 16px) * 1rem};

    --line-height-sans: 1.34;
    --letter-spacing-sans: 0.085em;
    // --text-stroke: 0.015em;
    --text-stroke: 0.05em;
    
    --letter-spacing: 0.05em;

    --min-font-size: 8px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size-xxsmall: #{math.div(13px, 16px) * 1rem};

        --font-size: 16px;
    }
}

@mixin font-sans-thin($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    text-transform: uppercase;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    text-transform: uppercase;
    -webkit-text-stroke: var(--text-stroke);
    letter-spacing: var(--letter-spacing-sans);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-text($line-height: 0) {
    font-family: var(--font-text);
    font-weight: normal;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
