#widget-landing {
    --font-size-title: var(--font-size-xxlarge);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --font-size-title: 21vw;
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-s);
    }
}

#widget-landing {
    @include z-index($z-index-bg);

    @include font-sans();
    padding: calc(var(--header-height) / 2 + var(--padding-v)) var(--padding-h) var(--padding-v);
    position: absolute;
    text-align: center;
    color: var(--primary-color);

    width: 100vw;
    overflow: hidden;
    
    h1,
    .title {
        @include font-sans-thin(.8);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-s);
    }
}
