.billboard-project {
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-l);
    --font-size: var(--font-size-xxsmall);
    --color: var(--primary-color);
    --width-slide: 100%;
    --slider-height: 100vh;

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xxs);
    }
}

.billboard-project {    
    @include font-text();
    font-size: var(--font-size);
    
    color: var(--color);
        
    h1 {
        @include font-text();
        font-size: var(--font-size);
        margin: 0
    }

    a {
       @include basic-a();
       color: var(--color); 
       display: inline-block;
    }

    &__slider {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        padding: var(--padding-v) var(--padding-h);
        width: 100%;

        align-items: flex-end;
        display: flex;
        justify-content: flex-start;
        
        > div {
            left: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            max-height: 100%;
            @include z-index(0);

            display: flex;
            flex-direction: column;
            user-select: none;
            
            > .holder {
                order: 1;
                position: relative;
                height: var(--slider-height);
                display: flex;
                flex-wrap: nowrap;
                overflow: hidden;
                gap: var(--gap);
            }
        }
    }

    &__item {
        position: relative;
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        overflow: hidden;

        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        max-height: 100%;
        width: 100%;

        opacity: 1 !important;

        svg {
            height: 100%;
            width: auto;
        }

        img {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }
    }

    .wrapper {
        position: relative;
        @include z-index(1);
        // mix-blend-mode: difference;
    
        width: 100%;

        .info *:not(a) {
            pointer-events: none;
        }
    }

    .thumbnails {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: rem(5);
        flex-grow: 1;
        height: rem(5);

        .thumb {
            height: rem(5);
            width: rem(5);
            opacity: .2;
            background-color: var(--color);
            border-radius: 50%;
            transform: opacity .1s ease-out;
            
            &.--active {
                opacity: 1;
            }
        }
    }

    @media (max-width: $smartphone) {
        .info,
        .info a,
        .info h1 {
            margin-bottom: var(--padding-xs);
        }

        &__slider {
            > div > .holder {
                -webkit-overflow-scrolling: touch;
                overflow: hidden;
                overflow-x: scroll;
                scroll-snap-type: x mandatory;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &__item {
            scroll-snap-align: start;
        }

        .wrapper {
            padding: var(--header-height) var(--padding-h) var(--padding-v);
            position: fixed;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            max-height: 100%;

            align-items: flex-start;
            flex-direction: column;
            display: flex;
            justify-content: flex-end;
            pointer-events: none;

            .content {
                backdrop-filter: blur(5px);
                padding: var(--padding-xxs);
                border-radius: rem(15);
                margin-bottom: var(--padding-v);
            }
        }

        .thumbnails {
            position: absolute;
            bottom: var(--padding-v);
            left: calc(var(--padding-h) + var(--padding-xxs));
        }
    }

    @media (min-width: $smartphone) {
        &__slider {
            height: var(--height);
        }

        .wrapper {
            padding: var(--header-height) var(--padding-h) var(--padding-v);
            position: fixed;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            max-height: 100%;

            align-items: flex-end;
            display: flex;
            justify-content: flex-start;
            gap: var(--padding-xxl);

            top: 0;
            left: 0;
            @include z-index(1);
        }

        .content {
            align-items: stretch;
            display: flex;
            justify-content: flex-start;
            gap: var(--padding-xxl);

            .info,
            .abstract {
                padding: var(--padding-xxs);
                border-radius: rem(20);
                backdrop-filter: blur(5px);
            }
            
            .info {
                flex: 0 0 rem(285);
            }
    
            .abstract {
                * {
                    max-width: rem(500);
                }
            }
        }

        .thumbnails {
            justify-content: flex-end;
        }
    }
}
